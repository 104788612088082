import React, {Component} from 'react';
//import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo_transparent.png';
import './slate-bootstrap.min.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import './App.css';
import InputForm from  './InputForm';
import Rates from './Rates';


const r = {
  apr: 4.157,
  fees: 1150,
  jumbo: false,
  lender: "chase",
  lender_id: "chase",
  monthly_pmt: 1454,
  points: 0,
  product_type: "conv",
  rate: 4.125,
  ratetable: "nerdwallet",
  term: 360
}

class App extends Component {
  constructor(props) {
    super(props);
    this.rates =[]
    this.state = {
      rates: [],
      loading: false
    };
    // this.setState({rates:[r]})
    this.state = {rates:[r]};
    this.submit = this.submit.bind(this);
   // setTimeout(() => this.setState({rates:[r,r,r,r]}),5000)
  }

  submit(data) {
    const qs = Object.keys(data.formData).map(key => `${key}=${data.formData[key]}`).join('&');
    const url = `/api/rates?${qs}`
    const r = new XMLHttpRequest();
    let last = 0,buffer='';
    this.rates = [];
    this.setState({rates: this.rates})
  
    const processBuffer = (d, finalize) => {
      if (d) buffer += d;
      var items = buffer.split('\n');
      if (!finalize) buffer = items.pop();   
      else this.setState({loading: false})
      items.forEach(d => {
        if (d.length < 5) return;
        setTimeout( () => {
          let item = JSON.parse(d.replace(/,$/,''));
          item.hash = Math.random();
          this.rates = this.rates.concat(item).sort( (a,b) => a.apr - b.apr);         
          this.setState({rates: this.rates});
        },finalize == true ? 0 : Math.random()*400 );
        
      });
    }
    this.setState({loading: true})
    r.open('GET',url,true);
    r.setRequestHeader('Content-Type','application/json;charset=UTF-8');
    r.send();
    r.onprogress = function() {
      processBuffer(r.responseText.slice(last));
      last = r.responseText.length;
    };
    r.onload =  function() {
      processBuffer(r.responseText.slice(last), true);
    };
  
  }
  render() {
    const {rates, loading} = this.state
    return (
      
      <Container className="p-3">
        <img src={logo} id="logo"/>
        {window.location.hash == '#rates' ? <InputForm submit={this.submit}/>  : '' }
        <br></br>
        <div>
          {loading ? <Spinner animation="border" className="loadingSpinner spinner-grow" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner> : ''}
          <Row>
            <Rates rates={rates}/>
          </Row>
        </div>
      </Container>
    )

  }
}


export default App;

