import React from 'react';
import Card from "react-bootstrap/Card";

const Rates = ({rates = []}) => {
  return rates.map(d => 
    <Card className="ratecard col-md-4" key={d.lender+d.apr}>
      <a href={d.url} target="_blank" rel="noreferrer">
        <Card.Body>
          Product: {d.product_type}<br/>
          Apr: {d.apr}%<br/>
          Rate: {d.rate}%<br/>
          Monthly pmt: {d.monthly_pmt}<br/>
          Points: {d.points}<br/>
          Lender: {d.lender}<br/>
          Ratetable: {d.ratetable}<br/>
        </Card.Body>
      </a>
    </Card>
  );
}

export default Rates;