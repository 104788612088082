import React, {Component} from 'react';
import Form from "react-jsonschema-form";


const uiSchema = {
  classNames: 'row',
  scenario_group: {'ui:widget': 'hidden'},
  scenario_id: {'ui:widget': 'hidden'},
  dti: {'ui:widget': 'hidden'},
  va: {
    'ui:widget': 'select',
    "ui:title": 'Veteran'
  },
  fha: {
    'ui:widget': 'select',
    "ui:title": 'FHA eligable'
  }
}

const formData = {
  product: '30y',
  fico: 700,
  property: 'sfr',
  occupancy: 'primary',
  purpose: 'purchase',
  amount: 300000,
  ltv: 0.8,
  state: 'CT',
  zip: '06840'
}

function ObjectFieldTemplate({ TitleField, properties, title, description }) {
  return (
    <div>
      <TitleField title={title} />
      <div className="row">
        {properties.map(prop => (
          <div
            className="col-lg-2 col-md-4 col-sm-6 col-xs-12"
            key={prop.content.key}>
            {prop.content}
          </div>
        ))}
      </div>
      {description}
    </div>
  );
}

const log = (type) => console.log.bind(console, type);

class InputForm extends Component {
  constructor(props) {
    super(props);
    this.state = {formData};
  }

  componentDidMount() {
    fetch('/api/schema/input')
      .then(response => response.json())
      .then(json => {
        const prop = json.properties;
        delete prop.scenario_group;
        delete prop.scenario_id;
        delete prop.dti;
        this.setState({schema: json});
      });
  }

  render() {
    const {submit} = this.props;
    const {schema, formData} = this.state;
    if (!schema) return <div>Loading</div>;
    const update = d => { this.setState({formData: d.formData}); submit(d)};
    return (
      <Form schema={schema} uiSchema={uiSchema} ObjectFieldTemplate={ObjectFieldTemplate} formData={formData}
        onChange={log("changed")}
        onSubmit={update}
        onError={log("errors")} />       
    );
    }
}

export default InputForm;